import React from 'react'
import { IconsProps } from 'msp-integrations'

const TelephoneIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path d="M15.16 22.72c-2.55 0-4.85-1.23-6.21-3.55l-4.16-7.2c-2.1-3.69-.67-8.09 3.42-10.44l.43-.25 3.97 6.88-3.6 2.08 3.55 6.15 3.6-2.08 4 6.87-.43.25c-1.51.87-3.08 1.29-4.57 1.29zM8.29 2.65c-3.26 2.12-4.37 5.76-2.62 8.82l4.16 7.2c1.81 3.09 5.45 3.93 8.97 2.13l-2.99-5.13-3.6 2.08-4.56-7.88 3.6-2.08-2.96-5.14z" />
    </svg>
  )
}

export default TelephoneIcon
